.App {
  flex-direction: row;
  display: flex;
  justify-content: center;
}
.App.About {
  flex-direction: column;
  align-items: flex-start;
  padding-left: 100px;
}
.App-header {
  height: 10vh;
  /* background-color: blue; */
  padding: 0px;
  margin: 0px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 7%;
}
.App-header.About {
  padding-bottom: 100px;
}
.header-button {
  /* background-color: red; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
  margin-left: 5px;
  margin-right: 5px;
  height: 50%;
  border: 1px solid white;

  color: white;
  font-family: 'IBM Plex Mono', monospace;
  font-size: 16px;
  text-decoration: none;
}
.App-container {
  width: 100vw;
  height: 100vh;
  padding: 0px;
  margin: 0px;

  background-color: blue;
}
.App-container.About {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}


.art {
  flex-direction: column;
  display: flex;
  width: 50%;
  overflow: scroll;
  padding-top: 40vh;
  max-height: 15vh;

  padding-bottom: 35vh;
  color: white;
}
::-webkit-scrollbar {display:none;}
.art-row {
  /* background-color: #61dafb; */
  flex-direction: row;
  display: flex;
  width: 100%;
  align-items: space-between;
  justify-content: space-between;

  font-family: 'IBM Plex Mono', monospace;
  font-size: 22px;
  font-weight: 900;

  margin-bottom: 50px;
}
.art-date {
  padding-left: 50px;
}

.about {
  font-family: 'IBM Plex Mono', monospace;
  font-size: 18px;
  line-height: 30px;
  font-weight: 700;
  color: white;
  text-align: justify;
  padding-top: 20px;

  width: 90%
}
.about.small {
  padding-top: 0px;
  /* padding-left: 70px; */
}
.submission-body {
  padding-left: 7%;
  padding-right: 7%;
  width: 86%;
}








.submit {
  align-self: center;
  padding: 20px;
  border: 1px solid white;

  margin-top: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.submit-text {
  background-color: blue;
  border: 1px solid white;
  padding: 5px;
  padding-left: 10px;
  
  font-family: 'IBM Plex Mono', monospace;
  font-size: 18px;
  line-height: 30px;
  font-weight: 700;
  color: white;
  width: 100%;
}
.submit-button {
  /* background-color: red; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
  margin-left: 20px;
  /* margin-right: 5px; */
  height: 40px;
  border: 1px solid white;

  color: white;
  font-family: 'IBM Plex Mono', monospace;
  font-size: 16px;
  text-decoration: none;
}
/* // add :hover */
.submit-button:hover {
  cursor: pointer;
}













.LOGO-text {
  display: flex;
  flex-direction: column;
  padding-top: 8vh;
  padding-right: 50px;
  color: white;
}
.LOGO-text.About {
  padding-top: 0vh;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.LOGO-row {
  height: 160px;
}
.LOGO-row.About {
  height: 160px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.App.Submit {
  /* background-color: red; */
  display: flex;
  flex-direction: column;
}




@media only screen and (max-width: 992px) {
  .App-container {
    /* background-color: red; */
  }
  .App {
    flex-direction: column;
  }
  .App-header.About {
    padding-bottom: 10px;
  }
  .LOGO-text {
    display: flex;
    flex-direction: row;
    padding-top: 0vh;
    margin-top: -5vh;
    align-self: center;
    height: 30vh;
    padding-right: 0px;
    display: flex;
    align-items: baseline;
  }
  .LOGO-text.About {
    /* background-color: aqua; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: -100px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .LOGO-row {
    height: 100%;
    display: flex;
    align-items: baseline;
    /* background-color: aqua; */
  }
  .LOGO-row.About {
    height: 90px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-start;
  }
  .App.Submit {
    /* background-color: red; */
    padding-left: 10px;
  }
  .LOGO-text.Submit {
    align-items: flex-start;
    justify-content: center;
    display: flex;
    flex-direction: column;
    /* background-color: aqua; */
    width: 100%;
    /* height: 300px; */
  }
  .about {
    font-size: 16px;
    line-height: 25px;
  }
  .art {
    width: 90%;
    align-self: center;
    padding-top: 0vh;
    max-height: 55vh;
    padding-bottom: 10vh;
  }
  .header-button {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
  }
  .art-row {
    font-size: 18px;
    margin-bottom: 25px;
  }
}









.x-a {
  font-size: 150px;
  font-family: 'ADLaM Display', cursive;
  padding-right: 2px;
}
.x-a.small {
  font-size: 100px;
}

.x-a2 {
  font-size: 150px;
  font-family: 'ADLaM Display', cursive;
  padding-left: 25px;
  padding-right: 10px;
}
.x-a2.small {
  font-size: 100px;
  padding-right: 0px;
  padding-left: 20px;
}

.x-r {
  font-size: 150px;
  font-family: 'Roboto Slab', serif;
}
.x-r.small {
  font-size: 100px;
}

.x-t {
  font-size: 190px;
  font-family: 'Yesteryear', cursive;
  padding-left:20px;
}
.x-t.small {
  font-size: 140px;
  padding-left: 10px;
}

.x-s {
  font-size: 130px;
  /* font-family: 'Yesteryear', cursive; */
  font-family: 'Libre Baskerville', serif;
}
.x-s.small {
  font-size: 80px;
}


.x-d1 {
  font-size: 150px;
  font-family: 'ADLaM Display', cursive;
  padding-right: 2px;
}
.x-d2 {
  font-size: 150px;
  font-family: 'Roboto Slab', serif;
}
.x-d3 {
  font-size: 150px;
  font-family: 'Libre Baskerville', serif;
}
.x-e1 {
  font-size: 120px;
  font-family:'Times New Roman', Times, serif;
  padding-left: 30px;
}
.x-v {
  font-size: 120px;
  /* padding-left: 10px; */
  font-family: 'Smokum', cursive;
}
.x-e2 {
  font-size: 100px;
  font-family: 'Righteous', cursive;
}
.x-r2 {
  font-size: 120px;
  font-family: 'Special Elite', cursive;
}
.x-y {
  font-size: 100px;
  font-family: 'Cherry Bomb One', cursive;
}
.x-w {
  font-size: 100px;
  font-family: 'MuseoModerno', cursive;
}
.x-h {
  font-size: 100px;
  font-family: 'Luckiest Guy', cursive;
}
.x-e3 {
  font-size: 100px;
  font-family: 'Black Ops One', cursive;
}
.x-r3 {
  font-size: 140px;
  font-family: 'Handjet', cursive;
}
.x-e4 {
  font-size: 100px;
  font-family: 'Racing Sans One', cursive;
}
.quote {
  padding-left: 30px;
}












@media only screen and (max-width: 600px) {
  .App-container {
    /* background-color: red; */
  }
  .App {
    flex-direction: column;
  }
  .LOGO-text {
    display: flex;
    flex-direction: row;
    padding-top: -0vh;
    margin-top: -2vh;
    align-self: center;
    height: 17vh;
    padding-right: 0px;



    display: flex;
    align-items: baseline;
    /* background-color: aqua; */
    /* background-color: green; */
    /* padding-bottom: 10vh; */
  }
  .LOGO-row {
    height: 100%;
    display: flex;
    align-items: baseline;
    /* background-color: aqua; */
  }
  .LOGO-text.About {
    /* display: flex; */
    /* flex-direction: row; */
    /* padding-top: -0vh; */
    /* margin-top: -2vh; */
    /* align-self: center; */
    width: 90%;
    /* background-color: red; */
    height: 17vh;
    /* background-color: green;  */
    margin-left: 3%;
  }
  .LOGO-row.About {
    height: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
    /* background-color: green;  */
  }
  .about {
    font-size: 14px;
    line-height: 22px;
    margin-left:0px;
    /* background-color: aqua; */
    /* width: 90vw; */
  }
  .about.small {
    padding-bottom: 10px;
    text-align: left;
  }
  .LOGO-text.About {
    align-self: flex-start;
    align-items: flex-start;
    /* background-color: red; */
    height: 100px;
    margin-bottom: 30px;
  }
  .App.About {
    /* background-color: green; */
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .art {
    width: 90%;
    align-self: center;
    padding-top: 0vh;
    max-height: 65vh;
    padding-bottom: 10vh;
  }
  .header-button {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
  }
  .art-row {
    font-size: 16px;
    margin-bottom: 25px;
     
  }
  .x-a {
    font-size: 80px;
  }
  .x-r {
    font-size: 90px;
  }
  .x-t {
    font-size: 100px;
    padding-left: 10px;
  }
  .x-a2 {
    font-size: 80px;
    padding-left: 30px;
    padding-right: 0px;
  }
  .x-s {
    font-size: 80px;
  }
  .x-d1 {
    font-size: 80px;
  }
  .x-d2 {
    font-size: 80px;
  }
  .x-d3 {
    font-size: 80px;
  }


  .x-a.small {
    font-size: 60px;
  }
  .x-r.small {
    font-size: 60px;
  }
  .x-t.small {
    font-size: 60px;
  }
  .x-a2.small {
    font-size: 60px;
    padding-right: 0px;
    padding-left: 20px;
  }
  .x-s.small {
    font-size: 60px;
  }

  .x-e1 {
    font-size: 60px;
    /* padding-left: 30px; */
  }
  .x-v {
    font-size: 60px;
  }
  .x-e2 {
    font-size: 60px;
  }
  .x-r2 {
    font-size: 60px;
  }
  .x-y {
    font-size: 60px;
  }
  .x-w {
    font-size: 60px;
  }
  .x-h {
    font-size: 60px;
  }
  .x-e3 {
    font-size: 60px;
  }
  .x-r3 {
    font-size: 60px;
  }
  .x-e4 {
    font-size: 60px;
  }
}